<!--  -->
<template>
  <el-dialog
      title="编辑设备"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      top="5vh"
      width="50%"
  >
    <el-form
        ref="form"
        inline
        :model="deviceObj"
        label-width="80px"
        label-position="left"
        size="small"
        :rules="rules"
    >
      <el-form-item label="设备类型" prop="brandName" class="w-2/5">
        <div class="w-64">
          <el-select v-model="deviceObj.deviceType" placeholder="设备类型">
            <el-option
                :label="item.typeName"
                :value="item.deviceType"
                v-for="(item, index) in deviceTypeList"
                :key="index"
            ></el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="厂家" prop="factoryName" class="w-2/5">
        <div class="w-64">
          <el-input
              v-model="deviceObj.factoryName"
              placeholder="请输入厂家"
              class="w-full"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="品牌" prop="brandName" class="w-2/5">
        <div class="w-64">
          <el-input
              v-model="deviceObj.brandName"
              placeholder="请输入品牌"
              class="w-full"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="型号" prop="prodModel" class="w-2/5">
        <div class="w-64">
          <el-input
              v-model="deviceObj.prodModel"
              placeholder="请输入型号"
              class="w-full"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="主板方案" prop="mainBoardPlan" class="w-2/5">
        <div class="w-64">
          <el-input
              v-model="deviceObj.mainBoardPlan"
              placeholder="请输入主板方案"
              class="w-full"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="操作系统" prop="operationSystem" class="w-2/5">
        <div class="w-64">
          <el-input
              v-model="deviceObj.operationSystem"
              placeholder="请输入操作系统"
              class="w-full"
          ></el-input>
        </div>
      </el-form-item>
      <!--      <el-form-item label="固件版本" prop="otaVer" class="w-2/5">-->
      <!--        <div class="w-64">-->
      <!--          <el-input v-model="deviceObj.otaVer" placeholder="请输入固件版本" class="w-full"></el-input>-->
      <!--        </div>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="APK版本" prop="apkVer" class="w-2/5">-->
      <!--        <div class="w-64">-->
      <!--          <el-input v-model="deviceObj.apkVer" placeholder="请输入APK版本" class="w-full"></el-input>-->
      <!--        </div>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="版本类型" prop="verType" class="w-2/5">-->
      <!--        <div class="w-64">-->
      <!--          <el-input v-model="deviceObj.verType" placeholder="请输入版本类型" class="w-full"></el-input>-->
      <!--        </div>-->
      <!--      </el-form-item>-->
      <el-form-item label="设备图片" prop="images" class="w-2/5">
        <div class="w-64">
          <el-upload
              class="h-48 w-48 border-gray-400 border-dashed border"
              action="#"
              :show-file-list="false"
              :http-request="imagesUrlUploader"
          >
            <img
                class="p-1 object-contain h-48 w-48"
                v-if="deviceObj.images"
                :src="deviceObj.images"
            />
            <i
                v-else
                class="el-icon-plus text-3xl h-48 w-48 justify-content-center align-items-center flex"
            ></i>
          </el-upload>
        </div>
      </el-form-item>
    </el-form>
    <div>
      支持功能及媒体协议
      <el-select
          v-model="deviceFunc"
          multiple
          placeholder="请选择"
          class="w-full mt-2"
      >
        <el-option
            v-for="item in deviceFunctionalList"
            :key="item.prop"
            :label="item.label"
            :value="item.prop"
        >
        </el-option>
      </el-select>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import { mapGetters } from 'vuex'
import oss from '@/utils/oss'

export default {
  data () {
    return {
      dialogVisible: false,
      rules: {},
      deviceObj: {
        apkUpdateSupport: 0,
        brandName: '',
        broadcastSupport: 0,
        certificatesSupport: 0,
        cloudControlSupport: 0,
        faceReSupport: 0,
        factoryName: '',
        gbteoeoSupport: 0,
        getImeiSupport: 0,
        images: '',
        intercomSupport: 0,
        liveSupport: 0,
        locationSupport: 0,
        mainBoardPlan: '',
        monitorSupport: 0,
        nfcSupport: 0,
        onvifSupport: 0,
        operationSystem: '',
        otaUpdateSupport: 0,
        prodModel: '',
        recSupport: 0,
        rtspSupport: 0,
        sensorSupport: 0,
        takePhotoSupport: 0,
        deviceType: 1,
        ucSupport: 0,
        webRtcSupport: 0,
        zoomControlSupport: 0
      },
      deviceFunc: []
    }
  },
  computed: {
    ...mapGetters(['deviceTypeList', 'deviceFunctionalList'])
  },
  watch: {
    deviceFunc: 'handlerSupport'
  },
  methods: {
    handlerSupport (val) {
      const funcList = this.deviceFunctionalList.map(item => item.prop)
      funcList.forEach(item => {
        this.deviceObj[item] = 0
      })
      val.forEach(item => {
        this.deviceObj[item] = 1
      })
    },
    async imagesUrlUploader (item) {
      const { file } = item
      this.deviceObj.images = await oss.upload(file)
      this.$refs.form.validateField('images')
    },
    submit () {
      this.$api.save('sysDevice', this.deviceObj).then(() => {
        this.dialogVisible = false
        this.$emit('on-submit')
      })
    },
    show (deviceType) {
      this.dialogVisible = true
    }
  },
  mounted () {
  }
}
</script>
